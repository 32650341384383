import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout"
import Search from "../components/search/search"

export default class TagTemplate extends React.Component {
  render() {
    const { tag } = this.props.pageContext
    const posts = this.props.data.allMarkdownRemark.nodes
    return (
      <Layout title={'tag='+tag}>
        <h1>posts tagged "{tag}"</h1>
        <Search
          posts={posts}
          localSearch={this.props.data.localSearchWords}
          placeholder='Search all posts...'
          prefix='words'
        />
      </Layout>
    )
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        fields {
          slug
          date
        }
        excerpt
        timeToRead
        frontmatter {
          title
          tags
          cover
          date
        }
      }
    }
    localSearchWords {
      index
      store
    }
  }
`
